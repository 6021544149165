import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Unauthenticated from "pages/Unauthenticated";
import NotFound from "pages/NotFound";

const Home = lazy(() => import("../pages/Home"));

import InteractionRoute from "./InteractionRoute";

export default function AppRoutes() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<InteractionRoute />}>
          <Route index element={<Home />} />
        </Route>
        <Route element={<Unauthenticated />} path="unauthenticated" />
        <Route element={<NotFound />} path="404" />
        <Route element={<Navigate to="404" />} path="*" />
      </Routes>
    </Suspense>
  );
}
