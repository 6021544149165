import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0058DE",
    },
    secondary: {
      main: "#616161",
    },
  },
  typography: {
    h1: {
      fontFamily: ["Untubu", "Roboto", "sans-serif"].join(","),
      fontSize: "26px",
      color: "#0058DE",
      fontWeight: 600,
    },
    h2: {
      fontFamily: ["Untubu", "Roboto", "sans-serif"].join(","),
      fontSize: "24px",
      color: "#0058DE",
      fontWeight: 500,
    },
    h3: {
      fontFamily: ["Untubu", "Roboto", "sans-serif"].join(","),
      fontSize: "22px",
      color: "#0058DE",
      fontWeight: 400,
    },
    h4: {
      fontFamily: ["Untubu", "Roboto", "sans-serif"].join(","),
      fontSize: "20px",
      color: "#616161",
      fontWeight: 400,
    },
    h5: {
      fontFamily: ["Untubu", "Roboto", "sans-serif"].join(","),
      fontSize: "18px",
      color: "#616161",
      fontWeight: 200,
    },
    h6: {
      fontFamily: ["Untubu", "Roboto", "sans-serif"].join(","),
      fontSize: "16px",
      color: "#616161",
      fontWeight: 100,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          fontWeight: 500,
          fontFamily: ["Untubu", "Roboto", "sans-serif"].join(","),
          textTransform: "none",
          fontSize: "1rem",
          textAlign: "center",
          height: "2.5rem",
          padding: "0 1.5em",
        },
      },
    },
  },
});

export default theme;
