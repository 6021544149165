import { ICollaborator } from "domain/ICollaborator";

import { addResponseInterceptorRefreshToken } from "@architecture-it/azure-b2c";
import { ServiceBase } from "@architecture-it/core";
import env from "@architecture-it/react-env";
import axios from "axios";
import { msalInstance } from "msalInstance";

const BASE_URL = env("CONTROL_TIEMPO_API");

class _CollaboratorService extends ServiceBase {
  constructor() {
    super(BASE_URL);

    //util for refresh token
    addResponseInterceptorRefreshToken(this.client, msalInstance, axios);
  }
  // implementar getLastSignings
  getCollaborator = () => this.client.get<ICollaborator>("/Colaborador/GetByEmail");
}

const CollaboratorService = new _CollaboratorService();

export default CollaboratorService;
