import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import HourglassDisabledOutlinedIcon from "@mui/icons-material/HourglassDisabledOutlined";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Grid } from "@mui/material";

export default function NavBar() {
  const isAuth = useIsAuthenticated();
  const { instance: instanceMsal } = useMsal();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="primary" position="static">
        <Toolbar>
          <Grid container>
            <Grid item textAlign="left" xs={1}>
              <IconButton aria-label="menu" color="inherit" edge="start" size="large">
                <HourglassDisabledOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item alignSelf="center" textAlign="left" xs={10}>
              <Typography color="white" variant="h4">
                TIEMPOS APP
              </Typography>
            </Grid>
            <Grid item md={1} textAlign="right" xs={1}>
              {isAuth ? (
                <IconButton
                  aria-label="menu"
                  color="inherit"
                  edge="start"
                  size="large"
                  onClick={() => instanceMsal.logoutRedirect()}
                >
                  <LogoutIcon />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="menu"
                  color="inherit"
                  edge="start"
                  size="large"
                  onClick={() => instanceMsal.loginRedirect()}
                >
                  <LoginIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
