import { combineReducers } from "@reduxjs/toolkit";
import alertReducer from "store/features/alert";
import modalReducer from "store/features/modal";
import collaboratorReducer from "store/features/collaborator";

import signing from "./features/signing";

export const reducers = combineReducers({
  alertReducer,
  modalReducer,
  signing,
  collaboratorReducer,
});
