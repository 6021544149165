import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthenticated = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item textAlign="center" xs={12}>
        <img alt="no autorizado" src="../img/unauthorized.png" width="125px" />
      </Grid>

      <Grid item xs={12}>
        <Typography textAlign="center" variant="h2">
          ¡Lo sentimos!
        </Typography>
        <Typography variant="h4">
          No tienes permisos para acceder a este modulo, debe solicitarselos al supervisor.
        </Typography>
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <Button variant="contained" onClick={() => navigate("/")}>
          Volver al inicio
        </Button>
      </Grid>
    </Grid>
  );
};

export default Unauthenticated;
