import { ISingningDetail } from "domain/ISigningDetail";
import { IQrSingIng } from "domain/IQrSinging";

import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store/store";
import { type IState } from "@architecture-it/core";

import { getLastSigningAction, postSigningAction } from "./asyncActions";

interface ISigningState extends IState<[]> {
  postSigning: IQrSingIng | null;
  lastSigning: ISingningDetail | null;
}

const initialState: ISigningState = {
  data: [],
  error: null,
  isLoading: false,
  postSigning: null,
  lastSigning: null,
};

export const SigningSlice = createSlice({
  name: "signing",
  initialState,
  reducers: {
    clearSigning: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    //getall
    builder.addCase(getLastSigningAction.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getLastSigningAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getLastSigningAction.fulfilled, (state, action) => {
      state.isLoading = false;

      state.lastSigning = action.payload as ISingningDetail;
    });
    builder.addCase(postSigningAction.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postSigningAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });

    builder.addCase(postSigningAction.fulfilled, (state, action) => {
      state.isLoading = false;
      let response = action.payload;

      state.postSigning = response as IQrSingIng;
    });
  },
});
export default SigningSlice.reducer;
export const { clearSigning } = SigningSlice.actions;
export const selectSigning = (state: RootState) => state.signing;
