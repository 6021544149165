import React from "react";

import NavBar from "./../components/NavBar/Index";
import classes from "./Main.module.scss";

interface IMainProps {
  children: React.ReactNode;
}

export default function Main({ children }: IMainProps) {
  return (
    <>
      <NavBar />
      <main className={classes.Main}>{children}</main>
    </>
  );
}
