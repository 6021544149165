import { ICollaborator } from "domain/ICollaborator";

import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store/store";
import { type IState } from "@architecture-it/core";

import { getCollaboratorAction } from "./asyncActions";

interface ICollaboratorState extends IState<[]> {
  collaborator: ICollaborator | null;
}

const initialState: ICollaboratorState = {
  data: [],
  error: null,
  isLoading: false,
  collaborator: null,
};

export const CollaboratorSlice = createSlice({
  name: "collaborator",
  initialState,
  reducers: {
    clearCollaborator: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCollaboratorAction.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCollaboratorAction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getCollaboratorAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.collaborator = action.payload as ICollaborator;
      state.error = action.payload ? null : "No se ha encontrado el mail solicitado.";
    });
  },
});
export default CollaboratorSlice.reducer;
export const { clearCollaborator } = CollaboratorSlice.actions;
export const selectCollaborator = (state: RootState) => state.collaboratorReducer;
