import { ISingningDetail } from "domain/ISigningDetail";
import { IDataFichaje } from "domain/IDataFichaje";

import { ServiceBase, type ICommonOptions } from "@architecture-it/core";
import env from "@architecture-it/react-env";
import { msalInstance } from "msalInstance";
import axios from "axios";
import { addResponseInterceptorRefreshToken } from "@architecture-it/azure-b2c";
import { IQrSingIng } from "domain/IQrSinging";

const BASE_URL = env("CONTROL_TIEMPO_API");

class _SigningService extends ServiceBase {
  constructor() {
    super(BASE_URL);

    //util for refresh token
    addResponseInterceptorRefreshToken(this.client, msalInstance, axios);
  }
  // implementar getLastSignings
  getLastSigning = () => this.client.get<ISingningDetail>("UsuarioHoras/UltimoFichaje");
  postSigning = (data: IDataFichaje) =>
    this.client.post<IQrSingIng[]>("UsuarioHoras/gestionHoras", data);
}

const SigningService = new _SigningService();

export default SigningService;
