import { createAsyncThunk } from "@reduxjs/toolkit";
import * as useCases from "app/Collaborator";

export const getCollaboratorAction = createAsyncThunk("getCollaborator", async () => {
  try {
    const collaborator = await useCases.getCollaborator();

    return collaborator;
  } catch (error: any) {
    return null;
  }
});
