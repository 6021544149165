import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item textAlign="center" xs={12}>
        <img alt="atention" src="../img/atencion.png" width="125px" />
      </Grid>

      <Grid item xs={12}>
        <Typography textAlign="center" variant="h2">
          ¡Lo sentimos - Error 404!
        </Typography>
        <Typography color="secundary" variant="h4">
          La página a la que está intentando acceder no existe.
        </Typography>
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <Button variant="contained" onClick={() => navigate("/")}>
          Volver al inicio
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;
